.institution-collaboration-chart {
    height: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    /* section specific css */

        .square {
            display: inline-block;
            height: 10px;
            min-width: 10px;
            &-collaborator {
                background-color: #D2D2D2;
            }
            &-shared {
                background-color: #7694A7;
            }
            &-upc {
                background-color: #7CB9E0;
            }
        }
    
    .scrolling-chart {
        max-height: 340px;
        overflow: hidden;
        overflow-y: auto;
    }
}
