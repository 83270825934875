.vega-bindings {
    // display: none;
    padding: 0px;
    .vega-bind {
        .vega-bind-name {
            display: none;
        }
        .vega-bind-radio {
            label {
                line-height: 18px;
                padding-right: 16px;
                input {
                    margin-right: 8px;
                }
            }
        }
    }
}