
@import "../../scss/custom-variables";
.list-universities {
    // * {
    //     background-color: $s-light;
    // }
    padding-top: 24px;
    padding-bottom: 80px;
    // .wave {
    //     border: 1px solid #C00;
    // }

    // .selected-item {
    //     color: #D60 !important;
    // }
}
