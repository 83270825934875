$spacer: 12px;
$navbar-height: 60px;


// Typography
// Based on trick to set font-size: 62.5% we need to overide default variables
$font-family-body: 'Barlow', sans-serif;
$font-family-headings: 'Ubuntu', serif;
$primary: #097AC2;
$s-dark: #097AC2;
$secondary: rgb(255, 221, 191);
$s-light: desaturate(lighten($s-dark, 65%), 30%);
$complementary: #dd6600;

// Typography
// Based on trick to set font-size: 62.5% we need to overide default variables

$font-size-base: 1.2rem;

$navbar-height: 60px;
$navbar-height-sm: 50px;