.institutions-list {
    // border: 1px solid #CCC;
    .collaborators-scrolling-list {
        max-height: 300px;
        overflow: hidden;
        overflow-y: auto;
    }
    .row {
        &.item {
            pointer-events: all;
            &:hover {
                cursor: pointer;
                font-weight: bold;
            }
        }
        &.item-disabled {
            pointer-events: none;
        }
    }
}