.collaboration-chart {
    // height: 100%;
    /* section specific css */
    // p {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     .square {
    //         display: inline-block;
    //         height: 10px;
    //         min-width: 10px;
    //         &-collaborator {
    //             background-color: #D2D2D2;
    //         }
    //         &-shared {
    //             background-color: #7694A7;
    //         }
    //         &-upc {
    //             background-color: #7CB9E0;
    //         }
    //     }
    // }
    .scrolling-chart {
        height: 400px;
        overflow: hidden;
        overflow-y: auto;
    }

    .bar {
        position: absolute;
        left: 0px;
        bottom: 6px;
        // height: 100%;
        min-height: 8px;
        z-index: 0;
        padding: 0px;
        p {
            &.percentage {
                font-size: 10px;
                display: block;
                position: absolute;
                top: -4px;
                left: 100%;
                padding-left: 6px;
                word-break: keep-all;
            }
            
            
        }
    }

    .item {
        &:hover {
            cursor: pointer;
            font-weight: bold;
        }
    }
}
