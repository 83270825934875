@import "../../scss/custom-variables";

.home, .section {
    display: flex;
    flex-direction: column;
    &.inactive {
        height: 100%;    
        overflow: hidden;
    }

    .container-years-filter {
    }
    .container-tabs {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding-bottom: 20px;
        .main-tabs {
            
        }
        .tab-content {
            flex-grow: 1;
            overflow: hidden;
            overflow-y: auto;
            .tab-pane {
            }
            .scrolling-tab {
                
            }
            // border: 2px solid #C00;
        }
    }
}
.home {
    .navbar {
        height: $navbar-height;
        min-height: $navbar-height;
        max-height: $navbar-height;
        position: fixed;
        left: 0px;
        right: 0px;
        width: 100%;
        z-index: 99;
    }
    .intro-block {
        margin-top: $navbar-height;
    }
}

h3 {
    margin: 0px;
    &.title {
        text-transform: uppercase;
        font-size: 12px;
        padding: 12px 0px;
        margin: 0px;
    }
}

.section {
    position: fixed;
    top: $navbar-height;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    overflow-y: auto;
}

.filter-entity-container {
    position: absolute;
    right: 0px;
    top: 0px;
}
