@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital@1&display=swap');


@import "./scss/custom-variables";
// @import "~bootstrap/scss/bootstrap";
// @import '../node_modules/bootstrap/scss/bootstrap';

html,
body {
  height: 100%;
  // Trick to set 1rem = 10px
  font-size: 75%; //62.5%;
}


p {
  font-family: $font-family-body;
}

.h1, .h2, .h3, .h4, .h5, h1, h2, h3, h4, h5 {
  font-family: $font-family-headings;
  font-weight: 700;
}

.btn {
  margin: 0.1rem;
  font-size: 0.9rem;
  font-family: $font-family-body;
  border-radius: 0px !important;
  &-sorbonne {
      border-radius: 0px !important;
      &:focus {
          background-color: #CCC;
          border-color: transparent;
      }
      // border: 1px solid #CCC;
  }
}

svg.marks {
  text {
      font-family: $font-family-body !important;
  }
}


body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  overflow: hidden;
  overflow-y: auto;

  // Titles
  .text-primary {
    color: $primary !important;
  }
  .bg-primary {
    background-color: $primary !important;
  }
  // App comes full height
  .root {
    height: 100%;
    .app-container {
      height: 100%;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  .react-select-container {
    max-width: 300px !important;
  }

  // SIRIS Utilities

  &.siris-theme {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $s-dark;
    }

    h2 {
      &.title {

        display: inline-block;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 500;
        border-bottom: 1px solid $s-dark;
        margin: 0px;
        padding-bottom: 8px;
        padding-right: 16px;
      }
    }
    .wordcloud-container {
      height: 400px;
      svg {
        text {
          fill: $s-dark;
        }
      }
    }
    .btn-s-link {
      cursor: pointer;
      text-decoration: none;
      color: $s-dark;
      &:hover {
        color: lighten($s-dark, 18%);

      }
    }
    .siris-card {
      cursor: pointer;
      &:hover {
        background-color: $s-light, 10%;
        border-color: $s-dark;
        // border-width: 2px;
      }
    }
    .treemap-container {
      path {
        cursor: pointer;
      }
    }
  }

  .bg-siris-dark {
    background-color: $s-dark;
  }

  .bg-siris-light {
    background-color: $s-light;
  }

  .accordion-arrow {
    position: absolute;
    right: 21px;
    top: 0px;
    font-size: 18px;
  }
}

.col {
  &-20 {
    width: 20% !important;
    min-width: 20% !important;
    max-width: 20% !important;
  }
}


