.choropleth-map {
    position: relative;
    /* section specific css */
    .country_shape {
        path {
            &:hover {
                cursor: pointer;
            }
        }
    }
    .selected-info {
        position: absolute;
        top: 10px;
        left: 0px;
    }
    p {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .square {
            display: inline-block;
            height: 10px;
            width: 10px;
            background-color: transparent;
            // &-min {
            //     background-color: #f2f2f2;
            // }
            // &-mean {
            //     background-color: #097AC2;
            // }
            // &-max {
            //     background-color: #00264D;
            // }
        }
    }
    svg {
        // height: 300px;
        width: 100%;
    }
}

.map-legend {
    color: #fff;
    position: absolute;
    padding: 6px 12px;
    // background-color: #FFFFFF;
    // border: 1px solid #f2f2f2;
    left: 12px;
    bottom: 12px;
    background: rgb(0, 38, 77);
    background: linear-gradient(90deg, rgba(0, 38, 77, 1) 0%, rgba(9, 122, 194, 1) 50%, rgba(242, 242, 242, 1) 100%);
}
