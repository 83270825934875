@import "../../scss/custom-variables";
.indicator-chart {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    // border: 1px solid #000;
    flex-direction: column;
    // width: fit-content;
    color: $primary;
    p {
        margin: 0px;
        padding: 0px;
        background-color: transparent;
        border: none;
    }
    &_label {
        text-transform: uppercase;

    }
    &-small {
        .indicator-chart {
            &_label {
                font-weight: normal;
                font-size: 11px;
            }
            &_value {
                font-weight: bold;
                font-size: 24px;
            }
        }
    }
    &-big {
        align-items: center;
        .indicator-chart {
            &_label {
                text-align: center;
                font-weight: bold;
                font-size: 12px;
                // padding-bottom: 12px;
            }
            &_value {
                // font-weight: bold;
                font-size: 36px;
                line-height: 36px;
                font-weight: normal;
                letter-spacing: -1px;
            }
        }
    }
    &-right {
        align-items: flex-end;
    }
}